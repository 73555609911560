


































































@import 'src/css/mixins';
.card-step-mobile {
  .card-global {
    position: relative;
    .circle {
      width: 64px;
      height: 64px;
      background: #017fff 0% 0% no-repeat padding-box;
      background-color: #017fff;
      border-radius: 50%;
      position: absolute;
      top: 0px;
      left: 0px;

      span {
        @include font-secondary(24px, bold);
        line-height: 34px;
        color: white;
      }
    }

    .card {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 5px 10px #00000014;
      border: 1px solid #dddddd;
      border-radius: 5px;
      height: 100%;

      h5 {
        @include font-secondary(20px, semibold);
        line-height: 28px;
        font-family: SuzukiPRORegular;
      }

      p {
        @include font-primary(18px, regular);
        line-height: 28px;
        color: #4b5e65;
        font-family: SuzukiPRORegular;
      }
    }
  }
}





































































@import 'src/css/mixins';
.search-niv {
  background: #0f2832 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 10px #00000014;
  border-radius: 5px;

  h3 {
    @include font-primary(20px, regular);
    line-height: 20px;
    color: #ffffff;
    font-family: SuzukiPRORegular;
  }

  .white {
    border-radius: 5px;
    width: 40%;

    @media screen and (max-width: '670px') {
      min-width: 230px;
    }

    @media screen and (max-width: '414px') {
      width: 100%;
      margin-right: 0px;
      min-width: 0px;
    }
  }

  .input-suzuki {
    max-width: 400px;
    height: 46px;
    background-color: #ffffff;
    border-radius: 5px;
    padding-top: 21px;
    font-family: SuzukiPRORegular !important;
  }

  .btn-niv {
    @include font-secondary(14px, semibold);
    line-height: 18px;
    max-width: 120px;
    height: 50px;
    border: 2px solid #ffffff;
    border-radius: 60px;
    color: #ffffff;
    font-family: SuzukiPRORegular !important;
  }

  .link-niv {
    text-decoration: none;
    @include font-primary(14px, regular);
    line-height: 14px;
    cursor: pointer;
  }
}

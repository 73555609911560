







































































@import 'src/css/mixins';
.card-niv {
  padding: 0px;

  div {
    padding: 25px;

    h4 {
      @include font-secondary(14px, semibold);
      line-height: 18px;
      letter-spacing: 0;
      color: #0f2832;
      font-family: SuzukiPRORegular;
    }

    p {
      @include font-primary(18px, regular);
      line-height: 28px;
      letter-spacing: 0;
      color: #4b5e65;
      font-family: SuzukiPRORegular;
    }
  }

  .column-left {
    border-right: 1px solid #dddddd;

    @media screen and (max-width: '935px') {
      border-right: 0px solid #dddddd;
    }
  }

  .column-right {
    .pdf-data {
      padding: 0px;
    }
  }
}

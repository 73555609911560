@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';





























































































@import 'src/css/mixins.scss';
.service-suzuki {
  padding-top: 50px;
  padding-bottom: 60px;

  h1 {
    padding: 0 0 40px;

    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 10px 40px;
    }
  }

  .col-left {
    padding: 0 40px 0 0;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 10px;
    }

    h2 {
      font-size: 24px;
      font-family: SuzukiPRORegular;
    }

    h2::after {
      content: '';
      display: block;
      height: 3px;
      width: 60px;
      background-color: #017fff;
      margin-top: 18px;
    }

    p {
      @include font-primary(16px, 'regular');
      color: #4b5e65;
      font-weight: 500;
      line-height: 28px;
      padding: 0 0 20px;
      letter-spacing: 0;
      font-family: SuzukiPRORegular;
    }

    .note {
      @include font-primary(14px, 'regular');
      line-height: 24px;
      color: #4b5e65;
      margin-top: 40px;
      padding-bottom: 60px;
    }
  }

  .col-right {
    padding: 0 10px;

    h2 {
      font-size: 24px;
      font-family: SuzukiPRORegular;
    }

    h2::after {
      content: '';
      display: block;
      height: 3px;
      width: 60px;
      background-color: #017fff;
      margin-top: 18px;
    }
  }

  .text {
    color: #0f2832;
    font-weight: 600;
    line-height: initial;
    margin: 0 0 20px 0;
    font-family: SuzukiPRORegular;
  }

  .card-niv {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 10px #00000014;
    border: 1px solid #dddddd;
    border-radius: 5px;
  }
}
